.sales {
    padding-top: 20px;
    padding-bottom: 60px;

    @media screen and (max-width: 1280px) {
        padding-top: 0px;
        padding-bottom: 40px;
    }
}
.sales_list {
    padding-top: 65px;

    @media screen and (max-width: 1280px) {
        padding-top: 20px;
    }
    @media screen and (max-width: 768px) {
        padding-top: 0px;
    }
}
.sales_elem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 35px;
    
    &--arhive {
        opacity: 0.3;
    }

    &__image {
        width: 49.5%;
    }
    &__text {
        width: 48%;
    }

    @media screen and (max-width: 992px) {
        &__image {
            width: 100%;
        }
        &__text {
            width: 100%;
        }
    }

    &__title {
        position: relative;
        display: block;
        text-align: left;
        padding-bottom: 45px;
        margin: 0 0 40px;        

        &::before {
            content: "";
            position: absolute;
            width: 90px;
            height: 7px;
            background-color: #d71b00;
            left: 0;
            bottom: 0;
        }
        @media screen and (min-width: 1480px) {
            font-size: 48px;
        }
        @media screen and (max-width: 1280px) {
            padding-bottom: 25px;
            margin: 0 0 20px; 
        }
    }
    p {
        margin: 0;
        line-height: 1.4em;
    }
    p + p {
        margin-top: 25px;
    }
}