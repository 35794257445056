.endosphere {
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 75px;
}
.endosphere_intro {
  margin-bottom: 65px;
}
.endosphere .container {
  max-width: 1590px;
}
.endosphere_intro__row {
  padding-top: 15px;
}
.endosphere_intro__left {
  width: 44%;
}
.endosphere_intro__right {
  width: 51%;
}
.endosphere_intro__text p {
  line-height: 1.55em;
  margin-bottom: 30px;
}
.endosphere_intro__block {
  position: relative;
  background-color: var(--lightgray);
  padding: 45px 0px 20px 40px;

  p:first-child {
    position: relative;
    padding-top: 40px;
    
    &::before {
      content: "";
      position: absolute;
      width: 50px;
      height: 5px;
      left: 0;
      top: 0;
      background-color: var(--red);
    }
    @media screen and (max-width: 1140px) {
      padding-top: 25px;
    }
  }
}
.endosphere_intro__billet {
  margin-left: 95px;
  padding: 35px 0px 15px 55px;
  margin-bottom: 50px;
}
.endosphere_intro__text {
  padding-left: 40px;
}
.endosphere_intro__block::before,
.endosphere_intro__billet::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 5px;
  transform: translateX(100%);
  width: 100vw;
  height: 100%;
  z-index: -1;
}
.endosphere_intro__block::before {
  background-color: var(--lightgray);
}
.endosphere_intro__billet::before {
  background-color: var(--dark);
}
.endosphere_zones {
  margin-bottom: 105px;
}
.endosphere_zones_sub {
  text-align: center;
}
.endosphere_zones__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  column-gap: 10px;
  row-gap: 30px;
  padding-top: 15px;
  margin: 0 0 40px;
}
.endosphere_zones__list li {
  position: relative;
  text-align: center;
  min-width: 100px;
  padding-top: 105px;
}
.endosphere_zones__list li::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  border: 3px solid #252525;
}
.endosphere_zones__list li:nth-child(1):before {
  background: url("/assets/img/endosphere_zones_01.svg") center center / 85%
    no-repeat;
}
.endosphere_zones__list li:nth-child(2):before {
  background: url("/assets/img/endosphere_zones_02.svg") center center / 65%
    no-repeat;
}
.endosphere_zones__list li:nth-child(3):before {
  background: url("/assets/img/endosphere_zones_03.svg") center center / 50% auto
    no-repeat;
}
.endosphere_zones__list li:nth-child(4):before {
  background: url("/assets/img/endosphere_zones_04.svg") center center / 50% auto
    no-repeat;
}
.endosphere_zones__list li:nth-child(5):before {
  background: url("/assets/img/endosphere_zones_05.svg") center center / 60% auto
    no-repeat;
}
.endosphere_indications {
  margin-bottom: 50px;
}
.endosphere_indications__row {
  align-items: flex-start;
}
.endosphere_indications__text {
  position: relative;
  background-color: var(--lightgray);
  width: 44%;
  padding: 60px 0 75px;
}
.endosphere_indications__text::before {
  content: "";
  position: absolute;
  display: block;
  width: 100vw;
  height: 100%;
  z-index: -1;
  background-color: var(--lightgray);
  top: 0;
  right: -30%;
}
.endosphere_indications__text h2 {
  text-align: left;
  margin: 0 0 30px;
}
.endosphere_indications__billet {
  position: relative;
  width: 52%;
  padding: 45px 0 20px 45px;
  margin-top: 135px;
  z-index: 1;
  left: -80px;
}
.endosphere_indications__billet::before {
  content: "";
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  right: 5px;
  transform: translateX(100%);
  background-color: var(--dark);
  width: 100vw;
  height: 100%;
}
.endosphere_indications__list {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  justify-content: space-between;
}
.endosphere_indications__list li {
  position: relative;
  padding-top: 15px;
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 1.75em;
}
.endosphere_indications__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 5px;
  background-color: var(--red);
}
@media screen and (min-width: 1800px) {
  .endosphere_indications__text::before {
    right: -210px;
  }
}
@media screen and (max-width: 1680px) {
  .endosphere_indications__billet {
    margin-top: 65px;
    left: -20px;
  }
}
@media screen and (max-width: 1400px) {
  .endosphere_indications {
    margin-bottom: 20px;
  }
  .endosphere_intro__row {
    padding-top: 10px;
  }
  .endosphere_intro__billet {
    margin-left: 45px;
  }
  .endosphere_indications__billet {
    margin-top: 45px;
    left: auto;
  }
}
@media screen and (max-width: 1280px) {
  .endosphere {
    padding-bottom: 50px;
  }
  .endosphere_intro__text {
    padding-left: 0;
  }
  .endosphere_intro__text p {
    margin-bottom: 20px;
  }
  .endosphere_intro__billet {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 992px) {
  .endosphere_intro__block::before,
  .endosphere_intro__billet::before,
  .endosphere_indications__text::before,
  .endosphere_indications__billet::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .endosphere_intro__left,
  .endosphere_intro__right {
    width: 100%;
  }
  .endosphere_intro__left img {
    margin: 0 auto;
  }
  .endosphere_intro__billet {
    margin-left: 0;
    padding: 30px 0px 15px 40px;
  }
  .endosphere_indications__text {
    width: 100%;
    padding: 40px 10px 20px 20px;
  }
  .endosphere_indications__billet {
    width: 100%;
    margin-top: 0;
    padding-left: 0px;
  }
  .endosphere_zones {
    margin-bottom: 65px;
  }
  .endosphere_zones__list {
    padding-top: 5px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .endosphere {
    padding-bottom: 20px;
  }
  .endosphere_intro {
    margin-bottom: 30px;
  }
  .endosphere_zones {
    margin-bottom: 60px;
  }
  .endosphere_intro__row {
    padding-top: 0;
  }
  .endosphere_intro__block,
  .endosphere_intro__billet {
    margin-top: 0;
    padding: 30px 0px 15px;
  }
  .endosphere_indications__text {
    padding: 40px 0 20px;
  }
  .endosphere_zones__list {
    grid-template-columns: repeat(3, 31.5%);
    justify-content: space-between;
    column-gap: initial;
    row-gap: 20px;
    margin: 0 0 20px;
  }
}
@media screen and (max-width: 600px) {
  .endosphere_indications__text h2 {
    margin-bottom: 20px;
  }
  .endosphere_indications__billet {
    padding-top: 35px;
  }
  .endosphere_indications__list {
    grid-template-columns: repeat(2, 48.5%);
  }
  .endosphere_indications__list li {
    padding-top: 15px;
    margin-bottom: 15px;
    line-height: 1.4em;
  }
}
@media screen and (max-width: 480px) {
  .endosphere_indications__list {
    grid-template-columns: 100%;
  }
  .endosphere_zones__list {
    grid-template-columns: repeat(2, 48.5%);
  }
}

.endosphere_face__text h2 {
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 15px;
  text-align: left;
  &::before {
    content: "";
    position: absolute;
    left: 14px;
    bottom: 0;
    width: 52px;
    height: 6px;
    background-color: var(--red);
  }
}
.endosphere_face__text {
  width: 60%;
  & p {
    max-width: 600px;
  }
}
.endosphere_face__img {
  width: 40%;
}
.endosphere_face__billet {
  padding: 55px 20px 10px 0px;
  margin-bottom: 55px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: 50vw;
    height: 100%;
    background-color: var(--dark);
    z-index: -1;
  }
}
.endosphere_face__list {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  justify-content: space-between;
  row-gap: 25px;
  padding-right: 30px;
  padding-bottom: 30px;
  max-width: 880px;
  & li {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 65px;
    padding-top: 15px;
    line-height: 1.5em;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 5px;
      background-color: var(--red);
    }
  }
}
.endosphere_preffs {
  padding: 35px 30px 50px;
  background-color: var(--lightgray);
  margin-bottom: 55px;
}
.endosphere_preffs__list {
  width: 100%;
  max-width: 1120px;
  padding-top: 20px;
  margin: 0 auto;
  & li {
    position: relative;
    padding-left: 58px;
    margin-bottom: 62px;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 30px;
      height: 5px;
      background-color: var(--red);
    }
  }
}
.endosphere_how {
  margin-bottom: 90px;
}
.endosphere_how__header {
  position: relative;
  background-color: var(--lightgray);
  padding: 65px 20px 50px 0px;
  width: 57.5%;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: 50vw;
    height: 100%;
    background-color: var(--lightgray);
    z-index: -1;
  }
  & h2 {
    position: relative;
    text-align: left;
    margin: 0;
    padding: 0 0 20px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 52px;
      height: 6px;
      background-color: var(--red);
    }
  }
}
.endosphere_how__billet {
  width: 90%;
  margin-left: auto;
  padding: 20px 20px 20px 45px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 14px;
    background-color: var(--dark);
    transform: translateY(-100%);
  }
}
.endosphere_body {
  margin-bottom: 75px;
}
.endosphere_body__img {
  width: 45.5%;
}
.endosphere_body__text {
  width: 54.5%;
}
.endosphere_body__text h2 {
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 15px;
  text-align: left;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 52px;
    height: 6px;
    background-color: var(--red);
  }
}
.endosphere_body__billet {
  padding: 65px 0px 40px 95px;
  margin-bottom: 45px;
  &::before {
    content: "";
    position: absolute;
    left: -1%;
    top: 0;    
    width: 100vw;
    height: 100%;
    background-color: var(--dark);
    z-index: -1;
  }
}
.endosphere_body__content {
  padding-left: 95px;
}
@media screen and (max-width: 1400px) {
  .endosphere_body__billet {
    padding: 45px 0px 30px 60px;
    margin-bottom: 45px;
  }
  .endosphere_body__content {
    padding-left: 60px;
  }
}
@media screen and (max-width: 992px) {
  .endosphere_face__text,
  .endosphere_body__text {
    width: 100%;
  }
  .endosphere_face__img,
  .endosphere_body__img {
    width: 100%;
  }
  .endosphere_face__img img,
  .endosphere_body__img img {
    margin: 0 auto;
  }
  .endosphere_face__billet::before,
  .endosphere_body__billet::before {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
  .endosphere_face__list {
    padding-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .endosphere_face__billet {
    padding: 35px 0px 10px 0px;
    margin-bottom: 35px;
  }
  .endosphere_face__list {
    grid-template-columns: repeat(2, 48.5%);
    row-gap: 20px;
    & li {
      min-height: initial;
      align-items: flex-start;
    }
  }
  .endosphere_preffs__list {
    padding-top: 0;
    & li {
     position: relative;
     padding-left: 54px;
     margin-bottom: 32px;
   }
  }
  .endosphere_how__header {
    padding: 35px 20px 40px 0px;
    width: 100%;
  }
  .endosphere_how__billet {
    width: 100%;
    padding: 20px 15px 20px 25px;
  }
  .endosphere_how {
    margin-bottom: 50px;
  }
  .endosphere_how__header::before {
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
  }
  .endosphere_body {
    margin-bottom: 40px;
  }
  .endosphere_body__billet {
    padding: 35px 0px 10px 0px;
    margin-bottom: 35px;
  }
  .endosphere_body__content {
    padding-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .endosphere_preffs {
    padding: 25px 10px 40px 15px;
  }
  .endosphere_preffs__list li {
    padding-left: 0px;
    padding-top: 15px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .endosphere_face__list {
    grid-template-columns: 100%;
    row-gap: 10px;
    & li {
      padding-top: 10px;
    }
  }
}