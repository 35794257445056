/* Страница Массаж */
.massage {
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 75px;
}
.massage h1 {
  margin-bottom: 45px;
}
.massage_row {
  margin-bottom: 80px;
}
.massage_row__text {
  width: 52.5%;
}
.massage_row--right .massage_row__text {
  width: 51%;
}
.massage_row__text p {
  line-height: 1.55em;
}
.massage_row__img {
  position: relative;
  width: 45%;
  padding-top: 40px;
}
.massage_row h2 {
  text-align: left;
  margin: 0 0 45px;
}
.massage_row__billet {
  z-index: 1;
  padding: 40px 15px 10px 0;
  margin-bottom: 30px;
}
.massage_row__billet::before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 5px;
  transform: translateX(-100%);
  z-index: -1;
  top: 0;
  background-color: var(--dark);
}
.massage_row--right .massage_row__billet {
  padding-left: 60px;
}
.massage_row--right .massage_row__billet::before {
  left: 0;
  transform: none;
}
.massage_manual .massage_row__billet {
  padding-bottom: 65px;
}
.massage_row__block {
  position: relative;
  padding-left: 125px;
  padding-bottom: 20px;
}
.massage_row__block::before {
  content: "";
  position: absolute;
  display: block;
  z-index: -1;
  width: 100vw;
  height: 170%;
  max-height: 545px;
  bottom: 0;
  right: -25%;
  background-color: var(--lightgray);
}
.massage_row--right .massage_row__block {
  padding-left: 70px;
  margin-left: 60px;
}
.massage_row--right .massage_row__block::before {
  left: 0;
  right: auto;
  height: 120%;
  max-height: initial;
}
.massage_row__block p + p {
  margin-top: 30px;
}
.massage_row__list {
  padding-bottom: 20px;
}
.massage_row__list li {
  position: relative;
  padding-top: 15px;
  margin-bottom: 20px;
  line-height: 1.6em;
}
.massage_row__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 5px;
  background-color: var(--red);
}
.massage_therapy {
  padding-top: 20px;
  margin-bottom: 50px;
}
.massage_therapy h2 {
  margin-top: 0;
}
.massage_therapy__row {
  padding-top: 65px;
}
.massage_therapy__img {
  width: 47%;
}
.massage_therapy__text {
  width: 49%;
  padding-top: 5px;
}
.massage_therapy__text p {
  line-height: 1.56em;
}
.massage_therapy__block {
  position: relative;
  width: 100%;
  max-width: 920px;
  margin: 30px 0 0 auto;
  padding: 35px 40px 20px;
  background-color: var(--lightgray);
}
.massage_therapy__block::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 5px;
  transform: translateX(100%);
  width: 100vw;
  height: 100%;
  background-color: var(--lightgray);
  z-index: -1;
}
.massage_therapy__block p {
  line-height: 1.55em;
  max-width: 750px;
}

@media screen and (min-width: 1800px) {
  .massage_row__img {
    margin-right: -25px;
  }
  .massage_row__block::before {
    right: -215px;
  }
}
@media screen and (max-width: 1400px) {
  .massage_row {
    margin-bottom: 70px;
  }
  .massage_row__block {
    padding-left: 65px;
  }
  .massage_row h2 {
    margin-bottom: 30px;
  }
  .massage_therapy {
    margin-bottom: 30px;
  }
  .massage_therapy__row {
    padding-top: 30px;
  }
  .massage_therapy__block {
    margin-top: 10px;
  }
  .massage_row--right .massage_row__billet {
    padding-left: 40px;
  }
  .massage_row--right .massage_row__block {
    padding-left: 40px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 1200px) {
  .massage_row__block {
    padding-left: 25px;
  }
  .massage_row--right .massage_row__billet {
    padding-left: 25px;
  }
  .massage_row--right .massage_row__block {
    padding-left: 25px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 992px) {
  .massage_row--right .massage_row__text,
  .massage_row__text,
  .massage_therapy__text {
    width: 100%;
    padding-top: 0;
  }
  .massage_row__img,
  .massage_therapy__img {
    width: 100%;
    order: -1;
    padding-top: 0;
  }
  .massage_row__img img,
  .massage_therapy__img img {
    margin: 0 auto 25px;
  }
  .massage_row__billet {
    margin-bottom: 0;
  }
  .massage_row__block {
    padding-top: 30px;
  }
  .massage_row__billet::before,
  .massage_therapy__block::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .massage_row__block::before {
    right: -15px;
    max-height: 100%;
  }
  .massage_endosphere__left,
  .massage_endosphere__right {
    width: 100%;
  }
  .massage_row--right .massage_row__block {
    margin-left: 0;
  }
  .massage_row--right .massage_row__billet::before,
  .massage_row--right .massage_row__block::before {
    left: -15px;
  }
}
@media screen and (max-width: 768px) {
  .massage h1 {
    margin-bottom: 30px;
  }
  .massage_row__billet {
    padding: 30px 0px 10px 0;
  }
  .massage_row__block {
    padding-left: 0;
  }
  .massage_manual .massage_row__billet {
    padding-bottom: 20px;
  }
  .massage_therapy {
    margin-bottom: 20px;
  }
  .massage_therapy__row {
    padding-top: 10px;
  }
  .massage_therapy__block {
    margin-top: 0;
    padding: 30px 0px 15px;
  }
}
@media screen and (max-width: 480px) {
  .massage_row {
    margin-bottom: 40px;
  }
  .massage_row__list li {
    line-height: 1.4em;
    margin-bottom: 15px;
  }
}
/* end Страница Массаж */